import React, { Component } from 'react';
import './Checkout.css';
import Navigation from '../Navigation/Navigation';
import ShippingForm from '../ShippingForm/ShippingForm';

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartData: JSON.parse(localStorage.getItem('cart')) || {},
            isLoading: true,
            cartProductInfo: [],
            amount: 0,
            activeForm: 'Shipping',
            shippingData: {},
        };
    }

    calculateTotalPrice = (cartData, cartProdData) => {

        if(!cartData) {
            cartData = this.state.cartData;
        }
        if(!cartProdData) {
            cartProdData = this.state.cartProdData;
        }
        // const { cartData, cartProdData } = this.state;
        let totalAmount = 0;
    
        Object.values(cartData).forEach((item) => {
            const product = cartProdData[item.productID];
            if (product && product.price && !isNaN(product.price.USD)) {
                const priceInUSD = parseFloat(product.price.USD);
                if (!isNaN(priceInUSD)) {
                    totalAmount += priceInUSD * item.quantity;
                }
            }
        });
    
        return totalAmount;
    };

    componentDidMount() {
        const { cartData } = this.state;
        const keysArray = Object.keys(cartData).map((key) => key.split('-')[0]);
        const promises = Object.values(keysArray).map((item) => 
            fetch(`https://etheling.com/api/products/${item}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Ошибка при получении данных с сервера');
                    }
                    return response.json();
                })
                .then((data) => ({ [`${item}`]: data }))
                .catch((error) => {
                    console.error('Ошибка при получении данных с сервера', error);
                    return null;
                })
        );
    
        Promise.all(promises)
            .then((results) => {
                const successfulResults = results.filter((result) => result !== null);
                const cartProdData = Object.assign({}, ...successfulResults);
                this.setState({ cartProdData });
                this.setState({ isLoading: false });
    
                const totalAmount = this.calculateTotalPrice(false, cartProdData);
                this.setState({ amount: totalAmount });
            })
            .catch((error) => {
                console.error('Произошла ошибка при выполнении запросов', error);
            });
    }

    handleTabClick = (formName) => {
        this.setState({ activeForm: formName });
    };

    handleShippingDataChange = (data) => {
        this.setState({ shippingData: data }, () => {
            const productsArray = Object.values(this.state.cartData);

            const combinedData = {
                ...data,
                products: productsArray
            };
            return this.sendShippingDataToAPI(combinedData);
        });
    };

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }

    sendShippingDataToAPI = async (data) => {
        console.log(data)
        const csrftoken = this.getCookie('csrftoken');

        try {
            // Замените URL на ваш endpoint
            const response = await fetch('https://etheling.com/api/create_order/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                throw new Error("Network response was not ok.");
            }
    
            const responseData = await response.json();

            console.log(responseData);
    
            // Проверьте, есть ли у вас ссылка в ответе
            if (responseData && responseData.payment_url) {
                localStorage.removeItem('cart');
                return responseData
                // window.location.href = responseData.payment_url;
            } else {
                console.error("API did not return a payment link.");
            }
        } catch (error) {
            console.error("Error sending shipping data to API:", error);
        }
    };

    handlePaymentDataChange = (data) => {
        this.setState({ paymentData: data });
    };


    render() {
        if (this.state.isLoading) {
			
			return (
				<>
				Loading...
			</>
			)
		}

        const { cartData, cartProdData, amount, activeForm, shippingData } = this.state
        const isEmpty = Object.keys(cartData).length === 0;
        return (
            <div className="checkout_page">
                <Navigation/>
                <div className="checkout_content">
                    {isEmpty ? (
                        <p className='emptyCart'>Empty</p>
                    ) : (
                        <div className="checkout_contetn_wrapper">
                            <div className="checkout_content_left">
                                <div className={"checkout_content_left_tabs_nav " + activeForm.toLowerCase()}>
                                    <div className={"checkout_content_nav_item" + (activeForm === "Shipping" ? " active" : "")}>
                                        Shippings
                                    </div>
                                    <span className="separator first"></span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10.1292 19.6841C4.94043 19.6841 0.734375 15.4748 0.734375 10.2822C0.734375 5.08958 4.94043 0.880371 10.1292 0.880371C15.3179 0.880371 19.524 5.08958 19.524 10.2822C19.524 15.4748 15.3179 19.6841 10.1292 19.6841ZM9.19252 14.0429L15.8347 7.39491L14.5062 6.06549L9.19252 11.3841L6.53473 8.72433L5.20631 10.0537L9.19252 14.0429Z" fill="black"/>
                                    </svg>
                                    <span className="separator last"></span>
                                    <div className={"checkout_content_nav_item" + (activeForm === "Payment" ? " active" : "")}>
                                        Payment
                                    </div>
                                </div>
                                
                                <ShippingForm
                                    data={shippingData}
                                    amount={amount}
                                    cartData={cartData}
                                    onDataChange={event => {
                                        this.handleShippingDataChange(event);
                                        this.handleTabClick('Payment');
                                    }}
                                />

                            </div>
                            <div className="checkout_content_right">
                                {Object.values(cartData).map((item, index) => (
                                    <div 
                                        className='cart_item'
                                        key={index}>
                                        <div className="cart_item_name">
                                        {`${cartProdData[item.productID].name} (${item.size})`}
                                        </div>
                                        <div className="cart_item_image">
                                        <img src={cartProdData[item.productID].images[0].image} alt={cartProdData[item.productID].images[0].alt} />
                                        </div>
                                        <div className="priceWrapper cart_item_price">
                                            {`${parseInt(cartProdData[item.productID].price.USD)} $`}
                                        </div>
                                    </div>
                                ))}
                                <div className="bottom-right_checkout">
                                    <div className="cart-amount priceWrapper">
                                        <span>Total</span>
                                        <span className='total'>{amount} $</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    )}
                </div>
                
            </div>
                
        )
    }
}

export default Checkout